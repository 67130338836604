<template lang="pug">
.sign-up
  h1 Criar Conta
  .no-sign-up
    span Já possui uma conta? &nbsp;
    Button(
      text="Clique aqui para fazer Login"
      @click="doNavigateToSignIn"
    )
  .info
    label.label(
      for="input-email"
    ) Email

    input.input(
      id="input-email"
      type="email"
      placeholder="email@email.com"
      v-model="email"
    )

    label.label(
      for="input-password"
    ) Senha

    input.input(
      id="input-password"
      type="password"
      placeholder="password"
      v-model="password"
    )

    label.label(
      for="input-name"
    ) Nome

    input.input(
      id="input-name"
      placeholder="Nome Completo"
      v-model="name"
    )

    span.error(
      v-if="showError"
    ) Não foi possível criar uma conta, verifique se todos os dados estão preenchidos

    Button(
      text="Criar conta"
      border="base"
      @click="doCreateUser"
    )
</template>

<script>
import {
  mapActions
} from 'vuex'

import {
  Button
} from '@/components/atoms'

export default {
  name: 'SignIn',
  data: () => ({
    email: '',
    password: '',
    name: '',
    showError: false
  }),
  components: {
    Button
  },
  methods: {
    ...mapActions('users',
      [
        'setSignUp'
      ]
    ),
    doNavigateToSignIn () {
      this.$router.push('/SignIn')
    },
    doCreateUser () {
      if (this.email && this.password && this.name) {
        const user = { email: this.email, password: this.password, name: this.name }
        this.setSignUp(user).then(resp => {
          if (!resp) {
            this.showError = true
          }
        })
      } else {
        this.showError = true
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  .sign-up
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    .no-sign-up
      display: flex
      flex-wrap: wrap
      justify-content: center
      margin: 16px 0
      font-size: 16px
      font-weight: 300

    .info
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column

    .label
      text-align: left
      min-width: 250px
      max-width: 100%

    .input
      border: none
      min-width: 250px
      border-bottom: 1px solid #000
      margin: 16px 0 24px
      font-size: 14px
      min-width: 250px
      max-width: 100%

    .error
      font-size: 16px
      color: red
      margin: 8px 0 16px
</style>
